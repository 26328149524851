// 严凯 V2.2.0
<template>
  <a-form ref="formRef" :rules="rules" :model="modalVal" class="form-add">
    <a-form-item label="人员姓名" name="staffName" class="form-item">
      <a-input
        :maxlength="30"
        placeholder="请输入人员姓名"
        v-model:value="modalVal.staffName"
        class="form-item-width"
      />
    </a-form-item>
    <a-form-item label="账号" name="account" class="form-item">
      <a-input
        :disabled="state.isIdentity"
        :maxlength="30"
        placeholder="请输入人员手机号用于登录账号"
        v-model:value="modalVal.account"
        class="form-item-width"
      />
      <p class="tips" style="width:468px;font-size:12px;color:#999999;line-height: 17px;">
        新账号无密码，人员可使用手机验证码登录后自行设置密码。如果该用户已存在其他企业，使用原密码。
      </p>
    </a-form-item>
    <a-form-item label="角色" v-if="current.identity !== 1" name="role" class="form-item">
      <a-select
        v-model:value="modalVal.role"
        placeholder="请选择角色"
        class="form-item-width"
        show-search
        allowClear
        :options="state.roleList"
        :filterOption="filterOption"
      >
        <!-- <a-select-option v-for="item in state.roleList" :value="item.id" :key="item.id">{{
                item.name
            }}</a-select-option> -->
      </a-select>
      <p class="tips" style="width:468px;font-size:12px;color:#999999;line-height: 17px;">
        若未选择角色，人员默认拥有用印管理和审批中心两个基本权限
      </p>
    </a-form-item>
    <a-form-item name="departIds" label="部门" class="form-item form-item-cascader">
      <!-- <a-tree-select
        :allowClear="true"
        style="width: 468px"
        v-model:value="modalVal.departIds"
        :tree-data="organizationalList"
        :dropdownStyle="{ 'max-height': '43vh' }"
        placeholder="请选择部门"
        treeNodeFilterProp="title"
        @change="handleChange"
        :replaceFields="{ children: 'childrenDepartmentList', title: 'departmentName', value: 'departmentId' }"
      >
      </a-tree-select> -->

      <a-cascader
        :allowClear="false"
        changeOnSelect
        popupClassName="depatment-cascader"
        style="width:468px"
        v-model:value="modalVal.departIds"
        :options="organizationalList"
        :display-render="displayRender"
        expand-trigger="hover"
        placeholder="请选择部门"
        @change="cascaderChange"
        :field-names="{
          children: 'childrenDepartmentList',
          label: 'departmentName',
          value: 'departmentId'
        }"
      />
    </a-form-item>
    <a-form-item label="部门主管" name="isLeader" class="form-item">
      <a-select v-model:value="modalVal.isLeader" class="form-item-width" :disabled="isLeaderDisabled">
        <a-select-option v-for="item in isLeaderOptions" :key="item.id" :value="item.id">{{
          item.name
        }}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="" class="form-item">
      <section style="margin-left: 82px">
        <a-button class="cancel-btn" @click="handleCancel">取消</a-button>
        <a-button class="submit-btn" :loading="loading" @click="comfirm">确定</a-button>
      </section>
    </a-form-item>
  </a-form>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, watch } from 'vue'
import { getLbwList, getRoleClassify } from '@/apis/businessManage'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const isLeaderOptions = [
  {
    id: 0,
    name: '否'
  },
  {
    id: 1,
    name: '是'
  }
]
export default defineComponent({
  components: {
    // DraggerUpload,
    // VirtualList,
    // ApprovalStaffItem
  },
  props: {
    types: {
      type: String,
      default: ''
    },
    current: {
      type: Object,
      default: () => {}
    },
    organizationalList: {
      type: Array,
      default: () => []
    },
    roleList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const store = useStore()
    const state = reactive({
      fileList: [],
      lbwList: [],
      isVirtualListScroll: 0,
      fileType: ['xls'],
      roleList: [],
      isIdentity: true
    })
    const formRef = ref()
    const router = useRouter()
    const modalVal = reactive({
      staffName: undefined,
      role: undefined,
      departIds: undefined,
      pass: undefined,
      isLeader: 0
    })
    const rules = ref()
    const isLeaderDisabled = ref(false)
    rules.value = {
      staffName: [
        {
          required: true,
          message: '请输入人员姓名'
        },
        {
          validator: (rule, value) => {
            var reg = new RegExp(
              '[`~!@#$%^&*()=|{}":;.+_",\\[\\]<>《》/\\\\?～！@#%¥……&*|{}（）【】「」｜‘；：”“。，、？—]'
            )
            var reg1 = new RegExp("['\\-']")
            if (reg.test(value) || reg1.test(value)) {
              return Promise.reject('人员姓名不能有特殊字符')
            }
            return Promise.resolve()
          },
          trigger: 'change'
        }
      ],
      account: [
        {
          required: true,
          message: '请输入账号'
        },
        { min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }
      ],
      departIds: [
        {
          type: 'array',
          required: true,
          message: '请选择部门'
        }
      ]
    }
    const handleCancel = () => {
      //   formRef.value.resetFields()
      // const lastData = JSON.parse(localStorage.getItem('staffCurrent'));
      // modalVal.staffName = lastData.name
      // modalVal.account = lastData.mobile
      // modalVal.role = lastData.role
      // modalVal.departIds = lastData.departIds
      // modalVal.isLeader = lastData.isLeader==null?0:lastData.isLeader

      router.back()
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) >= 0
    }

    // 部门级联选择器显示最后一级部门
    const displayRender = ({ labels }) => {
      return labels[labels.length - 1]
    }
    // 获取部门id路径
    const getIds = id => {
      if (!id) return [0]
      let ids = []
      const fn = arr => {
        arr.some(item => {
          if (item.departmentId == id) {
            ids.push(id)
            return true
          }
          if (item.childrenDepartmentIdSet.includes(+id)) {
            ids.push(item.departmentId)
            fn(item.childrenDepartmentList)
            return true
          }
          return false
        })
      }
      fn(props.organizationalList)
      return ids
    }

    const getRoleList = async () => {
      // 获取角色列表
      const res = await getRoleClassify()
      if (res.success) {
        state.roleList = res.data.map(item => {
          item.label = item.name
          item.value = item.id
          return item
        })
      }
    }
    const dataInit = () => {
      if (props.current.mobile) {
        modalVal.account = props.current.mobile
      }
      modalVal.staffName = props.current.staffName
      modalVal.role = props.current.roleId
      // modalVal.departIds = props.current.departmentId ? props.current.departmentId : 0
      modalVal.isLeader = props.current.isLeader == null ? 0 : props.current.isLeader
    }
    watch(
      () => props.organizationalList,
      val => {
        if (val.length > 0) {
          // 部门处理
          const departmentId = +props.current.departmentId
          modalVal.departIds = getIds(departmentId)
          if (modalVal.departIds.length === 1 && modalVal.departIds[0] === 0) {
            modalVal.isLeader = 0
            isLeaderDisabled.value = true
          }
        }
      },
      { deep: true }
    )
    const comfirm = () => {
      emit('update:loading', true)
      formRef.value
        .validate()
        .then(() => {
          modalVal.department = [...modalVal.departIds].pop()
          emit('submit', modalVal)
        })
        .catch(() => emit('update:loading', false))
    }
    // 部门选择change  部门为企业是部门超管为否切不能修改
    const cascaderChange = value => {
      if (value.length === 1 && value[0] === 0) {
        modalVal.isLeader = 0
        isLeaderDisabled.value = true
      } else {
        isLeaderDisabled.value = false
      }
    }
    onMounted(() => {
      dataInit()
      getRoleList()
      // 超管有全选修改手机号，超管不能修改自己的手机号
      if (store.state.user.userInfo.identity === 1) {
        state.isIdentity = props.current.identity === 1
      }
    })
    return {
      comfirm,
      formRef,
      modalVal,
      rules,
      state,
      handleCancel,
      filterOption,
      isLeaderOptions,
      displayRender,
      cascaderChange,
      isLeaderDisabled
    }
  }
})
</script>

<style lang="scss" scoped>
.mb-10 {
  margin-bottom: 10px;
}
.search-btn {
  margin-left: 16px;
  border-radius: 2px;
}
:deep(.ant-checkbox-wrapper) {
  display: block;
  margin-left: 0;
  font-weight: 500;
  color: #333333;
  font-size: 14px;
  margin-bottom: 12px;
  height: 22px;
  line-height: 22px;
}
.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;
  &:hover {
    border-color: transparent;
  }
}
.tips {
  width: 512px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: justify;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}
:deep(.ant-select) {
  .ant-select-selector {
    border-radius: 4px;
    line-height: 36px;
  }
}
.check-box {
  padding: 16px;
  background: #f7f8fa;
  max-height: 343px;
  overflow: hidden;
  overflow-y: scroll;
}
.form-item {
  margin-bottom: 16px;
}
.form-item-width {
  width: 468px;
}
// :deep(.ant-empty-normal){
//   margin: 120px 0;
// }
</style>

<style lang="scss">
.depatment-cascader {
  .ant-cascader-menu-item {
    width: 6.2em;
    box-sizing: content-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
